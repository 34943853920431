<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout fill-height column class="doctemplates template-list">
		<w-flex>
			<v-toolbar dense>
				<w-spacer />
				<w-search-input v-model="search" />
				<w-divider v-if="$vuetify.breakpoint.mdAndUp" class="ml-3" vertical />
				<w-flex shrink>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" @click="addTemplate">
								<v-badge bottom color="transparent" overlap right>
									<w-icon color="primary">add_circle</w-icon>
								</v-badge>
							</v-btn>
						</template>
						<span v-t="'actions.add'" />
					</v-tooltip>
				</w-flex>
				<w-spacer />
			</v-toolbar>
		</w-flex>
		<w-flex fill-height scroll-y my-2 mx-5>
			<w-layout justify-center row wrap>
				<w-flex class="px-2 table" shrink>
					<w-data-table
						class="elevation-2 mt-4"
						:headers="headers"
						:items="filteredDocumentsTemplates"
						:loading="loading"
						:no-data-text="$t('doctemplates.list.table.nodata')"
						:pagination.sync="paginationSync"
					>
						<template v-slot:items="{ item }">
							<td class="text-xs-center">
								<w-btn color="" flat icon="edit" mini small @click="goToEditor(item)" />
							</td>
							<td v-html="item.name"></td>
							<td>{{ item.created_at_formatted }}</td>
							<td class="text-xs-center">
								<v-btn icon ripple>
									<v-icon color="red" @click="deleteTemplate(item)">delete</v-icon>
								</v-btn>
							</td>
						</template>
					</w-data-table>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

import DocumentTemplateModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentTemplateModuleGuard'

export default {
	name: 'DocumentTemplatesList',
	mixins: [DocumentTemplateModuleGuard],
	data: function () {
		return {
			loading: false,
			search: '',
			templates: [],
			headers: [
				{ align: 'center', sortable: false, text: this.$t('actions.edit') },
				{ text: this.$t('doctemplates.list.table.fields.name'), value: 'name' },
				{
					text: this.$t('doctemplates.list.table.fields.created_at'),
					value: 'created_at'
				},
				{ align: 'center', sortable: false, text: this.$t('actions.delete') }
			],
			paginationSync: {
				rowsPerPage: 10,
				totalItems: 10
			}
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		}),
		formattedDocumentTemplate: function () {
			return Object.values(this.cloneObject(this.templates)).map(template => {
				template.created_at_formatted = this.$translateDateTime(template.created_at, 'tiny', true)
				return template
			})
		},
		filteredDocumentsTemplates: function () {
			const search = this.search.trim().toLowerCase()
			return search.length === 0
				? this.formattedDocumentTemplate
				: this.cloneObject(this.formattedDocumentTemplate)
						.filter(template => {
							return template.name.toLowerCase().includes(search)
						})
						.map(template => {
							const searchedTextRegEx = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
							template.name = template.name.replace(searchedTextRegEx, '<b>$&</b>')
							return template
						})
		}
	},
	watch: {
		accountingFirm: {
			handler: function (val) {
				if (val) {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
					this.listDocumentTemplates().finally(() => {
						this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
					})
				}
			},
			immediate: true
		}
	},
	methods: {
		addTemplate: function () {
			this.appService.goTo(`doc-templates-editor-new`)
		},
		listDocumentTemplates: async function () {
			this.loading = true
			this.templates = await this.service.listDocumentTemplates(this.accountingFirm.id).promise
			this.loading = false
		},
		deleteTemplate: async function (item) {
			this.loading = true
			const idxToDelete = this.templates.findIndex(e => e.id === item.id)
			await this.service.deleteDocTemplate(item.id)
			this.templates.splice(idxToDelete, 1)
			this.loading = false
		},
		goToEditor: async function (item) {
			this.loading = true
			this.appService.goTo({
				name: `doc-templates-editor`,
				params: {
					documentId: (parseInt(item.id) * 1000).toString(16).toString(16)
				}
			})
		}
	}
}
</script>

<style>
.doctemplates.template-list .table {
	min-width: 800px;
}
</style>
