// ============================================================================
// DocumentShareService
// --------------------
// DocumentShare module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/DocumentTemplateApi'

// ---------
// Internals
// ---------
const Private = { RULES_SET: {} }

// -------
// Exports
// -------
export default {
	listDocumentTemplates: function (accountingFirmId, cancelToken = null) {
		const callAnswer = API.listDocumentTemplates(
			{
				accounting_firm_id: accountingFirmId
			},
			{},
			cancelToken
		)

		return {
			cancelToken: callAnswer.cancelToken,
			promise: callAnswer.promise.then(res => res.data.data)
		}
	},
	getDocTemplate: function (templateId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getDocTemplate(accountingFirmId, templateId).then(res => res.data.data)
	},
	createDocTemplate: function (data) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.createDocTemplate(accountingFirmId, data).then(res => res.data.data)
	},
	updateDocTemplate: function (templateId, data) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.updateDocTemplate(accountingFirmId, templateId, data).then(res => res.data.data)
	},
	deleteDocTemplate: function (templateId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.deleteDocTemplate(accountingFirmId, templateId).then(res => res.data.data)
	},
	generateDocTemplate: function (templateId, data) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.generateDocTemplate(accountingFirmId, templateId, data).then(res => res.data.data)
	},
	getAllSymbols: function () {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getAllSymbols(accountingFirmId).then(res => res.data.data)
	},
	dummyDocTemplate: function (templateIdOrData) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.dummyDocTemplate(accountingFirmId, templateIdOrData).then(res => res.data)
	},
	setRules: function (translated = null) {
		Object.keys(Private.RULES_SET).forEach(key => delete Private.RULES_SET[key])
		Object.assign(Private.RULES_SET, {
			required: function (value = null) {
				let result = true
				if (!value || value.trim() === '') {
					result = translated.required
				}
				return result
			}
		})
	},
	getRules: function () {
		let result = null
		if (Private.RULES_SET) {
			result = Private.RULES_SET
		}
		return result
	},
	generateEngagementLetter: function (accountingFirmId, vendorId, projectId, additionalData) {
		const data = {
			accounting_firm_id: accountingFirmId,
			company_id: vendorId,
			project_id: projectId,
			...additionalData
		}
		return API.generateEngagementLetter(data).promise.then(response => response.data.data)
	}
}
