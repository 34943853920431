import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const listDocumentTemplates = (data, config = {}, cancelToken = null) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}

	const newCancelToken = Configurator.CancelToken.source()
	const query = {
		accounting_firm_id: data.accounting_firm_id
	}
	const url = 'contract-letter'

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			config,
			params: query
		})
	}
}

const getDocTemplate = (accountingFirmId, templateId) => {
	const url = `contract-letter/${templateId}`
	return axios.get(url, { params: { accounting_firm_id: accountingFirmId } })
}

const createDocTemplate = (accountingFirmId, data) => {
	const url = `contract-letter`
	return axios.post(url, data, { params: { accounting_firm_id: accountingFirmId } })
}

const updateDocTemplate = (accountingFirmId, templateId, data) => {
	const url = `contract-letter/${templateId}`
	return axios.patch(url, data, { params: { accounting_firm_id: accountingFirmId } })
}

const deleteDocTemplate = (accountingFirmId, templateId) => {
	const url = `contract-letter/${templateId}`
	return axios.delete(url, { params: { accounting_firm_id: accountingFirmId } })
}

const generateDocTemplate = (accountingFirmId, templateId, data) => {
	const url = `contract-letter/generate/${templateId}`
	return axios.post(url, data, { params: { accounting_firm_id: accountingFirmId } })
}

const getAllSymbols = accountingFirmId => {
	const url = `contract-letter/symbols`
	return axios.get(url, { params: { accounting_firm_id: accountingFirmId } })
}

const dummyDocTemplate = (accountingFirmId, templateIdOrData) => {
	if (typeof templateIdOrData === 'number') {
		const url = `contract-letter/dummy/${templateIdOrData}`
		return axios.post(url, null, { responseType: 'stream', params: { accounting_firm_id: accountingFirmId } })
	} else {
		const url = `contract-letter/dummy`
		return axios.post(url, templateIdOrData, { responseType: 'stream', params: { accounting_firm_id: accountingFirmId } })
	}
}

const generateEngagementLetter = (data, config, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}
	let configuration
	if (config) {
		configuration = config
	} else {
		configuration = {}
	}

	const url = `contract-letter/generate/${data.document_template_id}`
	const newCancelToken = Configurator.CancelToken.source()

	const payload = {
		folder_id: data.folder_id,

		data: {
			from_user_id: data.sender_id,
			project_id: data.project_id,
			to_user_id: data.receiver_id
		}
	}

	const query = {
		accounting_firm_id: data.accounting_firm_id
	}

	return {
		cancelToken: newCancelToken,
		promise: axios.post(url, payload, {
			cancelToken,
			config: configuration,
			params: query
		})
	}
}

export default {
	listDocumentTemplates,
	getDocTemplate,
	createDocTemplate,
	updateDocTemplate,
	deleteDocTemplate,
	generateDocTemplate,
	getAllSymbols,
	dummyDocTemplate,

	generateEngagementLetter
}
